export default {
  TIME_FORMAT: '{date}, {time} Uhr',
  BUTTON_BACK: 'Zurück',
  CARD_READER_CLIENT: 'Kartenlesegerät beim Kunden abgegeben',
  CARD_READER_ARZTPOOL: 'Kartenlesegerät zurück zu arztpool',
  SNACKBAR: {
    MESSAGE: 'Wir haben den Kartenleser erfolgreich aktualisiert.',
    CLOSE: 'Schließen',
  },
  PARTNER: {
    META: {
      TITLE: 'Service Management',
    },
    HEADING: 'Wählen Sie aus den Bewerbern Ihren Vertreter für den KV-Dienst aus',
    STEPS: {
      SELECTION: 'Vertreter auswählen',
      INFORMATION: 'Zuverlässig vertreten lassen',
    },
    APPLICANTS: {
      TITLE: 'Bewerbungen von Vertretungsärzten',
      NOTICE: 'Bitte wählen Sie einen Vertreter für Ihren KV-Dienst.',
      NO_APPLICANTS: 'Sie haben für diesen KV-Dienst noch keine Bewerbungen erhalten.',
      APPLICANTS_NUMBER_NOTICE:
        'Die Anzahl der Bewerber und die hier angezeigten Bewerber können voneinander abweichen, wenn die Qualifikationen einzelner Bewerber noch von arztpool überprüft werden.',
      ALL_INCLUSIVE: {
        TITLE: 'Sie haben das Rundum-Sorglos-Paket gebucht',
        TEXT: 'Sie haben das Rundum-Sorglos-Paket gebucht und dürfen sich nun zurücklehnen. Wir übernehmen für Sie die Auswahl des passenden Vertreters und die gesamte Auftragskommunikation. Bei Rückfragen können Sie Ihrem persönlichen Ansprechpartner eine Nachricht senden.',
      },
      SORT: {
        OFFER_ASC: 'Angebot aufsteigend',
        OFFER_DESC: 'Angebot absteigend',
      },
    },
    TABS: {
      SUBSTITUTE: {
        TITLE: 'Vertreter',
        HEADING: 'Checkliste',
        NOTICE: 'Hier können Sie jederzeit die Informationen zu Ihrem Vertreter einsehen',
        TEXT: 'Mit den folgenden Informationen stellen Sie sicher, dass Ihr Vertreter Ihren KV-Dienst zuverlässig vertreten kann. Bitte füllen Sie die folgende Checkliste aus. Falls Sie darüber hinaus noch Klärungsbedarf mit Ihrem Vertreter haben, können Sie hier jederzeit eine Nachricht an Ihren Vertreter schreiben.',
      },
      MESSAGES: {
        TITLE: 'Nachrichten',
        NOTICE: 'Hier können Sie jederzeit eine Nachricht an Ihren Vertreter schreiben und den Verlauf einsehen.',
      },
      EMERGENCY: {
        TITLE: 'Notfallscheine',
        HEADING: 'Anzahl Notfallscheine',
        TEXT: 'Ihr Vertreter wird Ihnen nach der Übernahme des KV-Dienstes die Anzahlen der verschiedenen Notfallscheine, die währen der Übernahme durch ihn ausgefüllt wurden, übermitteln. Erst nach die Anzahlen übermitteln und durch Sie bestätigt wurden, wird der KV-Dienst abgerechnet.',
      },
    },
    CHECKLIST: {
      BUTTON_SAVE: 'Informationen senden',
      ALREADY_SUBMITTED:
        'Sie haben die Checklist bereits ausgefüllt und an Ihren Vertreter für den KV-Dienst übermittelt. Unterhalb finden Sie Ihre übermittelten Informationen noch einmal für Ihre Übersicht.',
      VALIDATION_ERROR: 'Bitte füllen Sie alle Felder der Checkliste vor dem Absenden aus.',
      CARD_READER: {
        HEADING: 'Kartenlesegerät',
        TEXT: 'Ihr Vertreter benötigt ein/Ihr Kartenlesegerät, um diesen Dienst vertreten zu können.',
        PLACEHOLDER: 'Informationen zum Kartenlesegerät',
        ALL_INCLUSIVE: {
          TITLE: 'Sie haben das Rundum-Sorglos-Paket gebucht',
          TEXT: 'Sie haben sich bei dem Einstellen des KV-Dienstes für das Rundum-Sorglos-Paket entschieden. In diesem Service ist enthalten, dass wir Ihrem Vertreter Ihr Kartenlesegerät zur Verfügung stellen. Ein Mitarbeiter von arztpool nimmt dazu Kontakt mit Ihnen auf.',
        },
      },
      MEDICAL_DOCUMENTS: {
        HEADING: 'Medizinische Dokumente',
        TEXT: 'Falls Sie Ihrem Vertreter Notfallscheine oder sonstige medizinische Dokumente zur Verfügung stellen, bitten wir Sie hier genau zu beschreiben, wie viele Dokumente welcher Art Sie bereitstellen und wie Ihr Vertreter diese bekommen kann (Versand, Hinterlegung, o.ä.).',
        PLACEHOLDER: 'Wichtige Informationen zu medizinischen Dokumenten',
        ALL_INCLUSIVE: {
          TITLE: 'Sie haben das Rundum-Sorglos-Paket gebucht',
          TEXT: 'Sie haben sich bei dem Einstellen des KV-Dienstes für das Rundum-Sorglos-Paket entschieden. Wir organisieren für Sie die rechtzeitige Versendung von Unterlagen und Dokumenten.',
        },
      },
      ADDITIONAL: {
        HEADING: 'Sonstiges',
        TEXT: 'Wenn Sie noch weitere Details oder Informationen zu diesem Dienst haben, bitten wir Sie diese hier möglichst genau zu beschreiben.',
        PLACEHOLDER: 'Sonstige Informationen für Ihren Vertreter',
        ALL_INCLUSIVE: {
          TITLE: 'Sie haben das Rundum-Sorglos-Paket gebucht',
          TEXT: 'Sie haben sich bei dem Einstellen des KV-Dienstes für das Rundum-Sorglos-Paket entschieden. Wir lassen Ihren Vertreter alle benötigten Informationen zukommen.',
        },
      },
    },
    COUNTS: {
      NOTICE_WAITING: 'Ihr Vertreter hat die Anzahlen der Notfallscheine bisher noch nicht an Sie zur Kontrolle übermittelt.',
      NOTICE_CONFIRM: 'Hiermit bestätige ich, dass ich die angegebenen Anzahlen der Notfallscheine korrekt sind.',
      NOTICE_COMPLETED: 'Sie haben die Anzahlen der Notfallscheine am {date} bestätigt.',
      BUTTON_SAVE: 'Bestätigen und senden',
      INPUT: {
        PRIVATE: 'Anzahl privat versicherter Patienten',
        STATE: 'Anzahl gesetzlich versicherter Patienten',
        NECROPSY: 'Anzahl Totenscheine',
        PHONE: 'Anzahl telefonischer Beratungen',
      },
    },
  },
  SUBSTITUTE: {
    META: {
      TITLE: 'Service Management',
    },
    HEADING: 'Hier finden Sie alle Information, um den KV-Dienst zuverlässig vertreten zu können',
    TABS: {
      INFO: {
        TITLE: 'Dienstinformationen',
        TEXT: 'Unterhalb finden Sie alle Informationen, die Ihnen durch Ihren Partner zur Vertretung des KV-Dienstes zur Verfügung gestellt wurden. Überprüfen Sie diese sorgfältig.',
      },
      MESSAGES: {
        TITLE: 'Nachrichten',
        NOTICE: 'Hier können Sie jederzeit eine Nachricht an Ihren Auftraggeber schreiben und den Verlauf einsehen.',
      },
      EMERGENCY: {
        TITLE: 'Notfallscheine',
        HEADING: 'Anzahl Notfallscheine',
        TEXT: 'Bitte geben Sie die Informationen zu den Notfallscheinen zu diesem KV-Dienst an, um den Dienst abschließen zu können. Das geht ganz einfach, indem Sie hier die Anzahl der jeweiligen Scheine eintragen. Sobald Ihr Auftraggeber die Scheine bestätigt hat, kann der Dienst abgerechent werden.',
      },
    },
    CHECKLIST: {
      BUTTON_SAVE: 'Bestätigen und senden',
      NOT_READY: 'Ihr Partner hat die Informationen noch nicht ausgefüllt und übermittelt. Wir informieren Sie, sobald diese bereitstehen.',
      CONFIRMATION: 'Hiermit bestätige ich, dass ich die Dienstinformationen zur Kenntnis genommen und verstanden habe.',
      CONFIRMED: 'Sie haben die Dienstinformationen am {date} bestätigt.',
      VALIDATION_ERROR: 'Bitte bestätigen Sie, dass Sie die Dienstinformationen zur Kenntnis genommen haben.',
      CARD_READER: {
        HEADING: 'Kartenlesegerät',
      },
      MEDICAL_DOCUMENTS: {
        HEADING: 'Medizinische Dokumente',
      },
      ADDITIONAL: {
        HEADING: 'Sonstiges',
      },
    },
    COUNTS: {
      NOTICE_COUNTS: 'Erst wenn Sie die Anzahl der Notfallscheine übermittelt haben, kann der KV-Dienst abgeschlossen werden.',
      NOTICE_SUBMITTED:
        'Danke, dass Sie die Anzahlen übermittelt haben. Sobald Ihr Auftraggeber die Anzahlen bestätigt hat, kann der KV-Dienst abgerechnet werden. Bis dahin können Sie die Anzahlen jederzeit verändern.',
      NOTICE_COMPETED: 'Die Anzahlen wurden von Ihrem Auftraggeber bestätigt.',
      BUTTON_SAVE: 'Bestätigen und senden',
      INPUT: {
        PRIVATE: 'Anzahl privat versicherter Patienten',
        STATE: 'Anzahl gesetzlich versicherter Patienten',
        NECROPSY: 'Anzahl Totenscheine',
        PHONE: 'Anzahl telefonischer Beratungen',
      },
    },
  },
  MESSAGES: {
    TITLE_HISTORY: 'Nachrichten-Verlauf',
    TITLE_NEW: 'Neue Nachricht verfassen',
    NOTICE_NO_MESSAGES: 'Es wurden bisher noch keine Nachrichten für diesen KV-Dienst übermittelt.',
    BUTTON_SEND_PARTNER: 'Nachricht an Ihren Vertreter senden',
    BUTTON_SEND_SUBSTITUTE: 'Nachricht an Ihren Auftraggeber senden',
    MESSAGE_SUBJECT: 'Nachricht zum KV-Dienst am {date}',
  },
  SERVICE_SUMMARY: {
    SERVICE_MOBILE: 'Fahrdienst',
    SERVICE_OFFICE: 'Sitzdienst',
    HANDLING_FEE: 'Vermittlungsgebühr',
    APPLICANTS: '{count} Bewerbungen',
    BOOKMARKS: '{count} Beobachter',
    TIME: 'Uhr',
  },
  SUBSTITUTE_SUMMARY: {
    SERVICE_MOBILE: 'Fahrdienst',
    SERVICE_OFFICE: 'Sitzdienst',
    DURATION: '{hours} Stunden',
    TIME: 'Uhr',
    HOURLY: 'Honorar pro Stunde',
    TOTAL: 'Gesamthonorar',
    EMERGENCY: '+ {price} pro Notfallschein',
    HANDLING_FEE: 'Vermittlungsgebühr',
  },
  DOCUMENTS: {
    HEADING: 'Herunterladbare Dokumente',
    APPROBATION: 'Approbation',
    LIABILITY: 'Haftpflichtinformationen',
    ANNOUNCEMENT: 'Tauschmeldung',
    CONTRACT: 'Dienstvertretungsvertrag ',
    INVOICE: 'Rechnungsvorlage',
  },
  APPLICANT_CARD: {
    RATING: 'Bewertungen',
    RATING_STAR: 'Stern',
    PAST_SUBSTITUTIONS: 'arztpool-Einsätze',
    JOB_EXPERIENCE: 'Jahre Berufserfahrung',
    OWN_CASE: 'Bringt eigene Arzttasche mit',
    YEARLY_CHECK: 'Jährlicher arztpool-Check',
    BUTTON_OVERVIEW: 'Kostenübersicht ansehen',
    BUTTON_SELECT_APPLICANT: 'Bewerber auswählen',
    SUBSTITUTE: {
      TITLE: 'Honorar Vertreter',
      DESCRIPTION: 'Wird auf Basis Ihrer vorgegebenen Honorarspanne angegeben.',
    },
    ALL_INCLUSIVE: {
      TITLE: 'Vermittlungsgebühr für Rundum-Sorglos-Paket',
      DESCRIPTION: 'Die Gebühr wird auf Basis Ihres Einstelldatums kalkuliert.',
    },
    AGENCY_FEE: {
      TITLE: 'Vermittlungsgebühr',
      DESCRIPTION: 'Die Gebühr wird auf Basis Ihres Einstelldatums kalkuliert.',
    },
    PRIORITY: {
      TITLE: 'Zusatzoption: Priority',
      DESCRIPTION: 'Die Gebühr wird auf Basis Ihrer Einstellungen sowie Ihres Einstelldatums kalkuliert.',
      INSTEAD: 'statt',
    },
    PAYMENT: {
      TITLE: 'KV-Zahlungen an Sie',
      DESCRIPTION: 'Wird auf Basis des KV-Durchschnittshonorars kalkuliert.',
    },
    SUM: {
      TITLE: 'Gesamtkosten',
      DESCRIPTION: 'Zzgl. Beteiligungssumme pro Notfallschein',
    },
  },
};
