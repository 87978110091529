export default {
  NAVIGATION: {
    SERVICES: 'Dienste',
    SERVICE_SUBMISSIONS: 'Dienst Submissions',
    ALL_INCLUSIVE_SERVICES: 'Rundum Sorglos Dienste',
    TIER_SERVICES: 'Dienste nach Dringlichkeit',
    SERVICEAREAS: 'Dienstgebiete',
    USERS: 'Nutzer',
    INSURANCES: 'Versicherungen',
    ACCOUNTING: 'Buchhaltung',
    REPORTING: 'Reporting',
    LOGISTICS_HUB: 'Logistik Hub',
  },
  EXCEPTIONS: {
    HEADLINE: 'Es ist ein Fehler aufgetreten.',
    DESCRIPTION: 'Der folgende Fehler ist aufgetreten: ',
    CLOSE: 'Schließen',
  },
  SUDO_MODE: {
    BUTTON: 'Sudo Mode',
    WARNING: 'Sudo Mode Aktiv',
  },
  ORDER_CONFIRMATION: {
    GENERATE_ORDER_CONFIRMATION: 'Auftragsbestätigung Erzeugen',
    GENERATE_ORDER_SUMMARY: 'Auftragsübersicht Erzeugen',
    CLOSE: 'Schließen',
    TITLE: 'Auftragsbestatigung',
    VERIFY: 'Bitte schicken Sie die Auftragsbestatigung an:',
    MAIL: 'Mailadresse:',
    OR: 'oder',
    FAX: 'Faxnummer:',
    PERIOD: 'Zeitraum:',
    SERVICE_AREA: 'Dienstgebiet:',
    SUBTITLE: 'Anbei die Liste der Dienste, welche wir in Ihrem Auftrag zur Ausschreibung vorsehen wurden.',
    SUBTITLE2: 'Wir bitten Sie, diese Liste auf Vollstandigkeit und Richtigkeit zu prufen.',
    ESTABLISHMENT_NAME: 'Betriebstätte',
    ESTABLISHMENT_ADDRESS: 'Adresse:',
    FROM: 'Von',
    TO: 'Bis',
    LENGTH: 'Dauer',
    SECONDARY_SERVICE_AREA: 'Dienstgebiet',
    TYPE: 'Typ',
  },
  ORDER_SUMMARY: {
    DOWNLOAD: 'Auftragsübersicht runterladen',
    INFO: 'Bitte benutzen Sie bei jedem dienst das Kartenlesegerät des Kunden, wenn vorhanden. Ansonsten kann es zu Verzögerungen bezüglich der Auszahlung Ihres Honorars kommen.',
    DETAILS: 'Dienste im Detail',
    FROM: 'Von',
    TO: 'Bis',
    TITLE: 'Auftragsübersicht',
  },
  EVENT_LOGS: {
    HEADING: 'Event Log',
    TOGGLE: 'Event Log',
    EVENT_TYPES: {
      user_registered: 'Nutzer hat sich registriert',
      update_profile: 'Profil aktualisiert',
      partner_role_request: 'Rolle "Kunde" festgelegt',
      substitute_role_request: 'Rolle "Vertreter" festgelegt',
      update_subsitute: 'Vertreterdaten aktualisiert',
      create_application: 'Bewerbung hinzugefügt',
      create_assignment: 'Dienst vergeben',
      revoke_application: 'Bewerbung gelöscht',
      revoke_assignment: 'Dienstzuweisung gelöscht',
      message_read: 'Nachricht gelesen',
      create_office_service: 'Sitzdienst angelegt',
      create_mobile_service: 'Fahrdienst angelegt',
      conclude_service: 'Dienst abgeschlossen',
      substitute_verified: 'Vertreter verifiziert',
      update_service: 'Dienst aktualisiert',
    },
  },
  TAB_LABELS: {
    OPERATOR_SERVICES: 'Dienstverwaltung',
    OPERATOR_SERVICESUBMISSIONS: 'Dienst Submissions',
    OPERATOR_ALLINCLUSIVE_SERVICES: 'Rundum-Sorglos Dienste',
    OPERATOR_TIER_SERVICES: 'Dienste nach Dringlichkeit',
    OPERATOR_SERVICEAREAS: 'Dienstgebiete',
    OPERATOR_SINGLE_SERVICEAREA: 'Dienstgebiet',
    OPERATOR_USERS: 'Nutzerverwaltung',
    OPERATOR_INSURANCES: 'Versicherungen',
    OPERATOR_ACCOUNTING: 'Buchhaltung',
    OPERATOR_REPORTING_REGISTERED_SERVICES: 'Reporting Eingetragene Dienste',
    OPERATOR_REPORTING_REGISTERED_USERS: 'Reporting Registrierte Nutzer',
    OPERATOR_SINGLE_USER: 'Nutzer',
    OPERATOR_SINGLE_SERVICE: 'Dienst',
    OPERATOR_LOGISTICS_HUB: 'Logistik Hub',
  },
  CLOSE_TAB: 'Tab schließen',
  TABLE: {
    ROWS_PER_PAGE: 'Zeilen pro Seite',
    NO_DATA: 'Keine Daten geladen',
    SEPARATOR: 'von',
  },
  CONFIRM: {
    YES: 'Ja',
    NO: 'Nein',
  },
};
