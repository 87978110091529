import { Ref, isRef } from 'vue';
import { $t } from '@/plugins/i18n';

import ValidationRule from '@/definitions/interfaces/ValidationRule.i';
import ServiceSubstituteValidationRule from '@/definitions/interfaces/ServiceSubstituteValidationRule.i';

class Validation {
  /**
   * test a value against an array of rules
   * @param {Array} rules
   * @param {string} value
   * @return {boolean|string}
   */
  public test(rules: ValidationRule[] | ServiceSubstituteValidationRule[], value: any): boolean | string {
    let result: boolean | string = true;

    for (const i in rules) {
      if (rules[i](value) !== true) {
        result = rules[i](value);
        break;
      }
    }

    return result;
  }

  /**
   * merge multiple sets of rules together to have them combined
   * for example if a field should be a phone number but also be required
   * @param {Array} rules
   * @return {Array}
   */
  public mergeRules(...rules: any[]): any[] {
    return [].concat(...rules);
  }

  /**
   * get validation for required fields
   * @param {function|Ref|boolean} [skipValidation]
   * @return {Array}
   */
  public getRequiredValidator(skipValidation: ((value: string) => boolean) | Ref | boolean = false): ValidationRule[] {
    return [
      (
        value: string,
      ):
        | boolean
        | string => {
        let skip;

        // skip by reference
        if (isRef(skipValidation)) {
          skip = !!skipValidation.value;
        }

        // skip by callback function
        else if (typeof skipValidation === 'function') {
          skip = skipValidation(value);
        }

        // skip by boolean
        else {
          skip = skipValidation;
        }

        return skip || !!value || $t('VALIDATION.REQUIRED');
      },
    ];
  }

  /**
   * get validation for email fields
   * @return {Array}
   */
  public getEmailValidator(): ValidationRule[] {
    return [
      (
        value: string,
      ):
        | boolean
        | string => !!value || $t('VALIDATION.REQUIRED'),
      (
        value: string,
      ):
        | boolean
        | string => (value || '').length <= 64 || $t('VALIDATION.EMAIL.LENGTH'),
      (
        value: string,
      ):
        | boolean
        | string => {
        const pattern: RegExp = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/;
        return pattern.test(value) || $t('VALIDATION.EMAIL.INVALID');
      },
    ];
  }

  /**
   * get validator for password fields
   * @return {Array}
   */
  public getPasswordValidator(): ValidationRule[] {
    return [
      (
        value: string,
      ):
        | boolean
        | string => !!value || $t('VALIDATION.REQUIRED'),
      (
        value: string,
      ):
        | boolean
        | string => value.length >= 8 || $t('VALIDATION.PASSWORD.INVALID'),
      (
        value: string,
      ):
        | boolean
        | string => {
        const pattern: RegExp = /^(?=.*[a-z])(?=.*[A-Za-z])(?=.*[0-9])(?=.*[~!@#$%^&*\-_+=|";[\]{}()<>,./?\\])/;
        return pattern.test(value) || $t('VALIDATION.PASSWORD.INVALID');
      },
    ];
  }

  /**
   * get validator for confirm password field
   * @param {object} formInstance
   * @param {string} instanceField
   * @return {Array}
   */
  public getConfirmPasswordValidator(formInstance: { [p: string]: string }, instanceField: string = 'password'): ValidationRule[] {
    return [
      (
        value: string,
      ):
        | boolean
        | string => !!value || $t('VALIDATION.REQUIRED'),
      (
        value: string,
      ):
        | boolean
        | string => (value === formInstance[instanceField] && formInstance[instanceField] !== '') || $t('VALIDATION.PASSWORD.MISMATCH'),
    ];
  }

  /**
   * get validator for numbers
   * @param {boolean} onlyPositive
   * @return {Array}
   */
  public getNumberValidator(onlyPositive: boolean = true): ValidationRule[] {
    const rules: ValidationRule[] = [];

    // validate as number
    rules.push((value: string): boolean | string => {
      const pattern: RegExp = onlyPositive ? /^\d+$/ : /^-?\d+$/;
      return pattern.test(value) || $t('VALIDATION.NUMBER.INVALID');
    });

    return rules;
  }

  /**
   * get validation for phone numbers
   * @return {Array}
   */
  public getPhoneNumberValidator(): ValidationRule[] {
    return [
      (
        value: string,
      ):
        | boolean
        | string => {
        if (!value?.trim()) {
          return true;
        }

        const pattern: RegExp = /^[0-9]*$/;
        return pattern.test(value) || $t('VALIDATION.PHONE.INVALID');
      },
    ];
  }

  /**
   * get terms-of-service checkbox validator
   * @return {Array}
   */
  public getTosValidator(): ValidationRule[] {
    return [
      (
        value: string,
      ):
        | boolean
        | string => !!value || '',
    ];
  }

  /**
   * get accepted type file input validator
   * @param {string[]} acceptedFileTypes
   * @return {Array}
   */
  public getFileInputValidator(acceptedFileTypes: string[]): ValidationRule[] {
    const rules: ValidationRule[] = [];
    acceptedFileTypes.forEach((type: string) => {
      rules.push((value: any): boolean | string => value.length === 0 || value[0].type === type || $t('VALIDATION.FILE_INPUT.INVALID_TYPE'));
    });
    return rules;
  }

  /**
   * validate UUID v4 pattern
   * @param {string} testString
   * @return {boolean}
   */
  public validateUUIDPattern(testString: string): boolean {
    const _v4_regex = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);

    return _v4_regex.test(testString);
  }
}

export default new Validation();
